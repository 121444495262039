<template>
  <Card
    :has-body-padding="true"
    :show-close-button="showCloseButton"
    @close-button-click="handleCloseButtonClick"
  >
    <template
      #body
    >
      <p class="tw-mb-2.5">
        {{ leadText }}
      </p>
      <Form
        v-slot="{ meta }"
        @submit="handleFormSubmit"
      >
        <PasswordInput
          :autofocus="true"
          :data-lpignore="isForgotPasswordEnterPasswordView ? true : undefined"
          :label="isForgotPasswordEnterPasswordView ? 'New Password' : 'Password'"
          :full-width="true"
          :model-value="password"
          name="password"
          :required="true"
          :rules="rules"
          @update:model-value="handleUpdatePassword"
        />

        <Button
          class="tw-w-full tw-mt-4"
          :disabled="!meta.valid || !password"
          :display-block="true"
          :type="buttonType"
        >
          {{ buttonText }}
        </Button>
      </Form>
    </template>
  </Card>
</template>

<script>
/* eslint camelcase: off */
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { bannerTypes } from '@@/components/Common/Banner.vue';
import { getPromoCodeBannerType, navigateReturnTo } from '@@/utils/LoginUtils';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';
import LoginViewMixin from '@@/components/Login/LoginViewMixin';

export default {
  name: 'EnterPasswordForm',

  mixins: [
    LoginViewMixin,
  ],

  setup() {
    const config = useRuntimeConfig().public;
    return { config };
  },

  data() {
    return {
      return_to: null,
      token: null,
    };
  },

  computed: {
    ...mapState(useLoginStore, { password: (state) => state.credentials.password }),

    buttonText() {
      return this.isForgotPasswordEnterPasswordView ? 'Reset Password' : 'Continue';
    },

    buttonType() {
      return ButtonTypes.primary;
    },

    leadText() {
      if (this.isForgotPasswordEnterPasswordView) {
        return `Enter a new ${this.config.siteName} password below`;
      }

      return `Enter your ${this.config.siteName} password below.`;
    },

    rules() {
      return this.isForgotPasswordEnterPasswordView ? 'min:6' : undefined;
    },

    showCloseButton() {
      return !this.isForgotPasswordEnterPasswordView;
    },
  },

  mounted() {
    this.return_to = this.$route.query.return_to ?? '/user/favorites';
  },

  methods: {
    ...mapActions(useLoginStore, [
      'makeLoginRequest',
      'makeSettingsPasswordLinkVerifyRequest',
      'setCredentials',
      'setLoginView',
    ]),

    ...mapActions(useUserStore, ['setBanner']),

    handleCloseButtonClick() {
      this.password = null;
      this.setLoginView(loginViews.loginEnterEmail);
    },

    async handleFormSubmit() {
      try {
        let messages;
        let promo_code;
        let user;
        this.$loading.start();

        if (this.isForgotPasswordEnterPasswordView) {
          const payload = this.$route.query.p;
          user = await this.makeSettingsPasswordLinkVerifyRequest(payload);
        }
        else {
          ({ messages, promo_code, user } = await this.makeLoginRequest());
        }

        if (user) {
          this.onLoginSuccess(messages, promo_code);
        }
      }
      catch (e) {
        const { message } = parseOpenMountainApiError(e);

        this.$toast.open({
          dismissible: true,
          duration: 5000,
          message: message,
          type: 'error',
        });
      }

      this.$loading.finish();
    },

    handleUpdatePassword(value) {
      this.setCredentials({ password: value });
    },

    onLoginSuccess(messages, promo_code) {
      if (this.isForgotPasswordEnterPasswordView) {
        // Persist the banner props to session storage so that the user can be informed that their
        // password was changed successfully by displaying a banner on the next page after the user
        // is logged in to the site.
        const banner = {
          is_dismissable: true,
          message: 'Your password was changed successfully. Welcome back.',
          type: bannerTypes.membership,
        };
        this.setBanner({ banner, saveToSessionStorage: true });
      }
      else if (messages) {
        const type = getPromoCodeBannerType(promo_code);
        const banner = {
          is_dismissable: true,
          message: messages.join(' '),
          type,
        };
        this.setBanner({ banner, saveToSessionStorage: true });
      }

      navigateReturnTo(this.$router, this.return_to);
    },
  },
};
</script>
